// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();
  const intl = useIntl();

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title={intl.formatMessage({ id: "404.header" })}>
        <p>{intl.formatMessage({ id: "404.description" })}</p>
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
